import { default as register0KgVN4jECCMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/auth/register.vue?macro=true";
import { default as validateDEwmWmSOvFMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/auth/validate.vue?macro=true";
import { default as brandSWZQW77lOBMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/brand.vue?macro=true";
import { default as contact1bd2A1Lh3gMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/contact.vue?macro=true";
import { default as eventInfoms7cSqNPATMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/eventInfo.vue?macro=true";
import { default as eventspBBfTXEu4wMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/events.vue?macro=true";
import { default as facebook_45register4rX6WsMgadMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/facebook-register.vue?macro=true";
import { default as faqwsfzYyT8kuMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/faq.vue?macro=true";
import { default as indexByyF9K1hx1Meta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/index.vue?macro=true";
import { default as inviteygSfEKU3xYMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/invite.vue?macro=true";
import { default as loginczwPWtlFfSMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/login.vue?macro=true";
import { default as mainXJVulansC8Meta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/main.vue?macro=true";
import { default as meta_45registerm3mQP9DSomMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/meta-register.vue?macro=true";
import { default as notifypQ3Pm3MkGdMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/notify.vue?macro=true";
import { default as checkoutHeFdtQUqpxMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/package/checkout.vue?macro=true";
import { default as priceESFW573mJ4Meta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/package/price.vue?macro=true";
import { default as pairEventCN3G0DJ8qaMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/pairEvent.vue?macro=true";
import { default as pairOrderylF0tG0OvpMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/pairOrder.vue?macro=true";
import { default as checkoutRiCsNGl9jXMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/partner/checkout.vue?macro=true";
import { default as indexUW6uq3nxmCMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/partner/index.vue?macro=true";
import { default as profileMmvDSlw4WSMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/profile.vue?macro=true";
import { default as promptpay7rrXstD1JHMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/promptpay.vue?macro=true";
import { default as qrcoder718hGYMwfMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/qrcode.vue?macro=true";
import { default as shareCiMCzNgd7jMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/share.vue?macro=true";
import { default as shippingmXxmo4mOYzMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/shipping.vue?macro=true";
import { default as sortd72Tna6JJkMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/sort.vue?macro=true";
import { default as templatesm2ueCMtABpMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/templates.vue?macro=true";
import { default as test4WKIDYecwiMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/test.vue?macro=true";
import { default as uploadG3iwouIzRYMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/upload.vue?macro=true";
import { default as favoriteIOGTIUkv6TMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/brand/favorite.vue?macro=true";
import { default as indexMz65Md8c10Meta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/brand/index.vue?macro=true";
import { default as wishesapiItwYRLdMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/brand/wishes.vue?macro=true";
import { default as indexlmEIBvSMiPMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/contact/index.vue?macro=true";
import { default as sortC8SMD6K86aMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/desktop/sort.vue?macro=true";
import { default as homeDv0PgnnF5JMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/home.vue?macro=true";
import { default as indexQCxRzhLEzQMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/index.vue?macro=true";
import { default as liff139byIwlq9SMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/liff1.vue?macro=true";
import { default as indexAlAG9QHkhIMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/lineNotify/index.vue?macro=true";
import { default as mainKj1FXSyBo3Meta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/lineNotify/main.vue?macro=true";
import { default as faqsz5IusNrkdIMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/promptpay/faqs.vue?macro=true";
import { default as howToUseZ23upep7XFMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/promptpay/howToUse.vue?macro=true";
import { default as index_45design_452enXXEJHelqMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/promptpay/index-design-2.vue?macro=true";
import { default as index9gT9ZtQbASMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/promptpay/index.vue?macro=true";
import { default as mainQ4dCPJHUvJMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/promptpay/main.vue?macro=true";
import { default as qrcodeAndLinkkRAxI0hHEXMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/promptpay/qrcodeAndLink.vue?macro=true";
import { default as faqszuAD3SB8F5Meta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/realtimeSlides/faqs.vue?macro=true";
import { default as howToUsemTxVvGdZI1Meta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/realtimeSlides/howToUse.vue?macro=true";
import { default as index_46design_452l7iCtMTEo6Meta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/realtimeSlides/index.design-2.vue?macro=true";
import { default as indexbEtuqhGMzvMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/realtimeSlides/index.vue?macro=true";
import { default as link00TlSUtxUyMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/realtimeSlides/link.vue?macro=true";
import { default as mainwRQqbGDLX8Meta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/realtimeSlides/main.vue?macro=true";
import { default as photoseZZVN4niz4Meta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/realtimeSlides/photos.vue?macro=true";
import { default as registerdaDv3gjRVtMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/register.vue?macro=true";
import { default as index59al6mtLobMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/setting/index.vue?macro=true";
import { default as main8LlbNyJXkcMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/setting/main.vue?macro=true";
import { default as shippingAndPromptpay4IuAW8ypTjMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/shippingAndPromptpay.vue?macro=true";
import { default as indexv8WGMLb9WeMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/users/index.vue?macro=true";
import { default as mainYs8E2cbedWMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/users/main.vue?macro=true";
import { default as faqsBikGthpxiuMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/wish/faqs.vue?macro=true";
import { default as howToUseLe6vPufrHWMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/wish/howToUse.vue?macro=true";
import { default as index_45design_452eHGDFJPjO2Meta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/wish/index-design-2.vue?macro=true";
import { default as indexbVgRnrS5URMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/wish/index.vue?macro=true";
import { default as mainqZ42BAXqYxMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/wish/main.vue?macro=true";
import { default as qrcodeAndLinkW1iJvEXYL2Meta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/wish/qrcodeAndLink.vue?macro=true";
import { default as templatesdUIawkerlOMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/wish/templates.vue?macro=true";
import { default as softDeletedL28y6v1ta6Meta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/wishes/softDeleted.vue?macro=true";
import { default as sortInfoLH98qsr25yMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/wishes/sortInfo.vue?macro=true";
import { default as indexrCzw0tnxAcMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/wish/desktop/index.vue?macro=true";
import { default as InfopCe6eykxL6Meta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/wish/desktop/Info.vue?macro=true";
import { default as loginzcxQU9nu7zMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/wish/desktop/login.vue?macro=true";
import { default as photowish_45bookG6omaZngemMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/wish/desktop/photowish-book.vue?macro=true";
import { default as uploadfrZnyTmS3LMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/wish/desktop/upload.vue?macro=true";
import { default as preweddingcM4gxV2E0eMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/wish/prewedding.vue?macro=true";
import { default as wishesN53pDGwy19Meta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/wish/wishes.vue?macro=true";
export default [
  {
    name: "auth-register",
    path: "/auth/register",
    meta: register0KgVN4jECCMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "auth-validate",
    path: "/auth/validate",
    meta: validateDEwmWmSOvFMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/auth/validate.vue").then(m => m.default || m)
  },
  {
    name: "brand",
    path: "/brand",
    meta: brandSWZQW77lOBMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/brand.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "eventInfo",
    path: "/eventInfo",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/eventInfo.vue").then(m => m.default || m)
  },
  {
    name: "events",
    path: "/events",
    meta: eventspBBfTXEu4wMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/events.vue").then(m => m.default || m)
  },
  {
    name: "facebook-register",
    path: "/facebook-register",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/facebook-register.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invite",
    path: "/invite",
    meta: inviteygSfEKU3xYMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/invite.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginczwPWtlFfSMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "main",
    path: "/main",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/main.vue").then(m => m.default || m)
  },
  {
    name: "meta-register",
    path: "/meta-register",
    meta: meta_45registerm3mQP9DSomMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/meta-register.vue").then(m => m.default || m)
  },
  {
    name: "notify",
    path: "/notify",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/notify.vue").then(m => m.default || m)
  },
  {
    name: "package-checkout",
    path: "/package/checkout",
    meta: checkoutHeFdtQUqpxMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/package/checkout.vue").then(m => m.default || m)
  },
  {
    name: "package-price",
    path: "/package/price",
    meta: priceESFW573mJ4Meta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/package/price.vue").then(m => m.default || m)
  },
  {
    name: "pairEvent",
    path: "/pairEvent",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/pairEvent.vue").then(m => m.default || m)
  },
  {
    name: "pairOrder",
    path: "/pairOrder",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/pairOrder.vue").then(m => m.default || m)
  },
  {
    name: "partner-checkout",
    path: "/partner/checkout",
    meta: checkoutRiCsNGl9jXMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/partner/checkout.vue").then(m => m.default || m)
  },
  {
    name: "partner",
    path: "/partner",
    meta: indexUW6uq3nxmCMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/partner/index.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "promptpay",
    path: "/promptpay",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/promptpay.vue").then(m => m.default || m)
  },
  {
    name: "qrcode",
    path: "/qrcode",
    meta: qrcoder718hGYMwfMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/qrcode.vue").then(m => m.default || m)
  },
  {
    name: "share",
    path: "/share",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/share.vue").then(m => m.default || m)
  },
  {
    name: "shipping",
    path: "/shipping",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/shipping.vue").then(m => m.default || m)
  },
  {
    name: "sort",
    path: "/sort",
    meta: sortd72Tna6JJkMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/sort.vue").then(m => m.default || m)
  },
  {
    name: "templates",
    path: "/templates",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/templates.vue").then(m => m.default || m)
  },
  {
    name: "test",
    path: "/test",
    meta: test4WKIDYecwiMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/test.vue").then(m => m.default || m)
  },
  {
    name: "upload",
    path: "/upload",
    meta: uploadG3iwouIzRYMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/upload.vue").then(m => m.default || m)
  },
  {
    name: "v2-brand-favorite",
    path: "/v2/brand/favorite",
    meta: favoriteIOGTIUkv6TMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/brand/favorite.vue").then(m => m.default || m)
  },
  {
    name: "v2-brand",
    path: "/v2/brand",
    meta: indexMz65Md8c10Meta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/brand/index.vue").then(m => m.default || m)
  },
  {
    name: "v2-brand-wishes",
    path: "/v2/brand/wishes",
    meta: wishesapiItwYRLdMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/brand/wishes.vue").then(m => m.default || m)
  },
  {
    name: "v2-contact",
    path: "/v2/contact",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "v2-desktop-sort",
    path: "/v2/desktop/sort",
    meta: sortC8SMD6K86aMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/desktop/sort.vue").then(m => m.default || m)
  },
  {
    name: "v2-home",
    path: "/v2/home",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/home.vue").then(m => m.default || m)
  },
  {
    name: "v2",
    path: "/v2",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/index.vue").then(m => m.default || m)
  },
  {
    name: "v2-liff1",
    path: "/v2/liff1",
    meta: liff139byIwlq9SMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/liff1.vue").then(m => m.default || m)
  },
  {
    name: "v2-lineNotify",
    path: "/v2/lineNotify",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/lineNotify/index.vue").then(m => m.default || m)
  },
  {
    name: "v2-lineNotify-main",
    path: "/v2/lineNotify/main",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/lineNotify/main.vue").then(m => m.default || m)
  },
  {
    name: "v2-promptpay-faqs",
    path: "/v2/promptpay/faqs",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/promptpay/faqs.vue").then(m => m.default || m)
  },
  {
    name: "v2-promptpay-howToUse",
    path: "/v2/promptpay/howToUse",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/promptpay/howToUse.vue").then(m => m.default || m)
  },
  {
    name: "v2-promptpay-index-design-2",
    path: "/v2/promptpay/index-design-2",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/promptpay/index-design-2.vue").then(m => m.default || m)
  },
  {
    name: "v2-promptpay",
    path: "/v2/promptpay",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/promptpay/index.vue").then(m => m.default || m)
  },
  {
    name: "v2-promptpay-main",
    path: "/v2/promptpay/main",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/promptpay/main.vue").then(m => m.default || m)
  },
  {
    name: "v2-promptpay-qrcodeAndLink",
    path: "/v2/promptpay/qrcodeAndLink",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/promptpay/qrcodeAndLink.vue").then(m => m.default || m)
  },
  {
    name: "v2-realtimeSlides-faqs",
    path: "/v2/realtimeSlides/faqs",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/realtimeSlides/faqs.vue").then(m => m.default || m)
  },
  {
    name: "v2-realtimeSlides-howToUse",
    path: "/v2/realtimeSlides/howToUse",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/realtimeSlides/howToUse.vue").then(m => m.default || m)
  },
  {
    name: "v2-realtimeSlides-index.design-2",
    path: "/v2/realtimeSlides/index.design-2",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/realtimeSlides/index.design-2.vue").then(m => m.default || m)
  },
  {
    name: "v2-realtimeSlides",
    path: "/v2/realtimeSlides",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/realtimeSlides/index.vue").then(m => m.default || m)
  },
  {
    name: "v2-realtimeSlides-link",
    path: "/v2/realtimeSlides/link",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/realtimeSlides/link.vue").then(m => m.default || m)
  },
  {
    name: "v2-realtimeSlides-main",
    path: "/v2/realtimeSlides/main",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/realtimeSlides/main.vue").then(m => m.default || m)
  },
  {
    name: "v2-realtimeSlides-photos",
    path: "/v2/realtimeSlides/photos",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/realtimeSlides/photos.vue").then(m => m.default || m)
  },
  {
    name: "v2-register",
    path: "/v2/register",
    meta: registerdaDv3gjRVtMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/register.vue").then(m => m.default || m)
  },
  {
    name: "v2-setting",
    path: "/v2/setting",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/setting/index.vue").then(m => m.default || m)
  },
  {
    name: "v2-setting-main",
    path: "/v2/setting/main",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/setting/main.vue").then(m => m.default || m)
  },
  {
    name: "v2-shippingAndPromptpay",
    path: "/v2/shippingAndPromptpay",
    meta: shippingAndPromptpay4IuAW8ypTjMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/shippingAndPromptpay.vue").then(m => m.default || m)
  },
  {
    name: "v2-users",
    path: "/v2/users",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/users/index.vue").then(m => m.default || m)
  },
  {
    name: "v2-users-main",
    path: "/v2/users/main",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/users/main.vue").then(m => m.default || m)
  },
  {
    name: "v2-wish-faqs",
    path: "/v2/wish/faqs",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/wish/faqs.vue").then(m => m.default || m)
  },
  {
    name: "v2-wish-howToUse",
    path: "/v2/wish/howToUse",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/wish/howToUse.vue").then(m => m.default || m)
  },
  {
    name: "v2-wish-index-design-2",
    path: "/v2/wish/index-design-2",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/wish/index-design-2.vue").then(m => m.default || m)
  },
  {
    name: "v2-wish",
    path: "/v2/wish",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/wish/index.vue").then(m => m.default || m)
  },
  {
    name: "v2-wish-main",
    path: "/v2/wish/main",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/wish/main.vue").then(m => m.default || m)
  },
  {
    name: "v2-wish-qrcodeAndLink",
    path: "/v2/wish/qrcodeAndLink",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/wish/qrcodeAndLink.vue").then(m => m.default || m)
  },
  {
    name: "v2-wish-templates",
    path: "/v2/wish/templates",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/wish/templates.vue").then(m => m.default || m)
  },
  {
    name: "v2-wishes-softDeleted",
    path: "/v2/wishes/softDeleted",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/wishes/softDeleted.vue").then(m => m.default || m)
  },
  {
    name: "v2-wishes-sortInfo",
    path: "/v2/wishes/sortInfo",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/v2/wishes/sortInfo.vue").then(m => m.default || m)
  },
  {
    name: "wish-desktop",
    path: "/wish/desktop",
    meta: indexrCzw0tnxAcMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/wish/desktop/index.vue").then(m => m.default || m)
  },
  {
    name: "wish-desktop-Info",
    path: "/wish/desktop/Info",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/wish/desktop/Info.vue").then(m => m.default || m)
  },
  {
    name: "wish-desktop-login",
    path: "/wish/desktop/login",
    meta: loginzcxQU9nu7zMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/wish/desktop/login.vue").then(m => m.default || m)
  },
  {
    name: "wish-desktop-photowish-book",
    path: "/wish/desktop/photowish-book",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/wish/desktop/photowish-book.vue").then(m => m.default || m)
  },
  {
    name: "wish-desktop-upload",
    path: "/wish/desktop/upload",
    meta: uploadfrZnyTmS3LMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/wish/desktop/upload.vue").then(m => m.default || m)
  },
  {
    name: "wish-prewedding",
    path: "/wish/prewedding",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/wish/prewedding.vue").then(m => m.default || m)
  },
  {
    name: "wish-wishes",
    path: "/wish/wishes",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-connect-liff/pages/wish/wishes.vue").then(m => m.default || m)
  }
]